import React from 'react'
import styled from "styled-components";
import { Helmet } from 'react-helmet'
// tw refers to tailwind
import tw, { theme, GlobalStyles } from "twin.macro"
import { graphql, useStaticQuery } from 'gatsby'

// Adding standard header and footer
import { Layout } from '../components/common'

// Gatsby preloading
import { Link } from 'gatsby'

// in-line css
import { css } from '@emotion/css'

// dat format
import { formatDate } from "../components/utils/time/DateFormat"




export interface Blog {
created_at: string;
description: string;
title: string;
updated_at: string;
url: string;
}
  


// const blogData = {courseserver:
//   {
//       blogs : [
//           {
//             blog_created_at: "2021-08-11T00:00:00.000-00:00", 
//             blog_author_name: "White Owl Education",
//             blog_title: "Use Explainable AI to Prove Image Detection Works",
//             blog_description: "Learn about heatmaps for image classification to pass the GCP Machine Learning Engineer exam!",
//             blog_url: "/blog/2021/08/05/explainable-ai-overview/"
//         }
//       ]
//   }      
// };



// https://codesandbox.io/embed/github/ben-rogerson/twin.examples/tree/master/react-emotion?fontsize=14&hidenavigation=1&module=%2Fsrc%2FApp.js&theme=dark

// https://github.com/donjo9/next-styled-tailwind/blob/0af25fc465a84333104c56e5ebbd90444c457b9d/pages/index.tsx
const Background = styled.div`
  ${tw`text-white`}
  ${tw`flex flex-col  h-screen`}
  ${tw`bg-elegant-white`}
  /* ${tw`bg-gradient-to-b from-purple-800 to-blue-800`} */
  width: 100%;
  overflow: auto;
`;

const HR = styled.hr`
        height: 3px;
        background-color: ${theme`colors.elegant-dk-brown`};
        border: none;
`;


const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const titleFontStyle = css`
    h1 {
        font-size: 2.55rem;
        font-weight: 700;
    }

    h3 {
        font-size: 1.15rem;
        font-weight: 500;
    }
`;


const composedStyle = css`
    ${tailwindStyle};
    ${titleFontStyle};
`;

export function GetBlogYear(blog: Blog){
    var createdAt = blog.created_at;
    return createdAt.substring(0,4);
}

/*
Parse first 4 digigts of created_at and return years from high to low.
*/
function getBlogYears(blogs : Blog[]) {

    const blogYears = blogs.map(GetBlogYear);
    const blogYearsSet = new Set(blogYears);
    
    const unsortedBlogYearsArray = Array.from(blogYearsSet)
    
    const sortedBlogYearsArray = unsortedBlogYearsArray.sort(function(a, b) {
        return parseInt(b) - parseInt(a);
    })

    return sortedBlogYearsArray;

}


function getYearLine(year: string){

    let items:any = []

    const dateJSX = <h2 tw="text-elegant-green flex ml-2 mb-6 mt-4">{year}</h2>;
    const lineJSX = <HR tw="mb-4"/>;

    items.push(dateJSX);
    items.push(lineJSX);

    return items;

}

function getSortedBlogsByYear(sortedBlogs: Blog[], year: string) : Blog[]
{
    let BlogsMatchYear: Blog[] = [];
    
    for(const blog of sortedBlogs){
        let BlogYear = GetBlogYear(blog)
        if(BlogYear === year){
            BlogsMatchYear.push(blog)
        }
    }
    
    return BlogsMatchYear;
}

function getBlogByYearJSX(sortedBlogsByYear: Blog[]){

    let count = 0;
    let BlogYearItems = sortedBlogsByYear.map( (blog) => {
        count = count + 1;
        let keyPrefix = count.toString() + blog.title;

        // TODO - I need unique keys for each part
       
        // A link can only take in the suffix of the url
        let localURL = new URL(blog.url);
        return (
            <div key={keyPrefix + "div1"} tw="lg:text-3xl my-2 py-2 px-10 flex flex-1 flex-col w-full">
                <Link tw="" to={localURL.pathname}>

                    <span tw="w-full text-elegant-dk-brown hover:text-elegant-dk-brown text-xl" className={css({fontWeight: 700})} >{blog.title}</span>
                    
                </Link>
                <span tw="w-full text-elegant-brown text-base">{formatDate(blog.created_at)}</span>
            </div>
        );
    })
    return BlogYearItems;
}




function getBlogJSX(blogs: Blog[]){

    // first pass of blogs
    let sortedBlogYears = getBlogYears(blogs);

    let items:any  =[]

    var sortedBlogs = blogs.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
    })

    for (const year of sortedBlogYears)
    {
        const yearLineItems = getYearLine(year);

        // Second Pass of Blogs is not efficient
        const sortedBlogsByYear : Blog[] =  getSortedBlogsByYear(sortedBlogs,year)

        items.push(yearLineItems)
        items.push(getBlogByYearJSX(sortedBlogsByYear));
    }

    return items;
}


const BlogListPage = ({data}) => {
    // Keep everything inside the component so that you can use static query?
    const blogQuery = graphql`
    query {
        gateway {
            blogs {
                title
                url
                created_at
            }
        }
    }
    `;

    const blogData = useStaticQuery(blogQuery)
    

    




    const nameOfThisFile = "/blog/"
    const title = "Blog"
    const description = "List of White Owl Education Blogs"

    const breadcrumbLD = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.whiteowleducation.com/"
            },
                        {
                "@type": "ListItem",
                "position":2,
                "name": "Blog",
                "item": "https://www.whiteowleducation.com/blog/"
            }           
        ]
    }

    let count : number = 0;

    let blogJSXItems = getBlogJSX(blogData.gateway.blogs);

    return (
       <>
       <Layout
            pageInfo={{
                "title": `${title}`, 
                "description": `${description}`, 
            }}
            pathname={nameOfThisFile}
        >
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(breadcrumbLD, undefined, 4)}
            </script>
        </Helmet>
        <div className={composedStyle}>
            <GlobalStyles />
                <Background>
                    <section tw="relative">

                        <div tw="container flex flex-col">
                            <h1 tw="text-elegant-green flex ml-2 mt-6 mb-2 justify-start">Blogs</h1>
                            {blogJSXItems}
                            
                        </div>
                    </section>
                </Background>
        </div>
        </Layout>
        </>
    );
}

export default BlogListPage;